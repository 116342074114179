<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner">
      <b-card class="mb-0 py-3">
        <h2 class="brand-text">パスワードを再設定しました</h2>
        <p class="sub-brand-text">
          パスワードの更新が完了しました。
        </p>
        <p class="sub-brand-text">
          ログインページから再度ログインしてください。
        </p>
        <div class="btn__setPw-wrap btn">
          <router-link class="btn__setPw" :to="{name: 'login'}">ログイン画面へ</router-link>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
export default {
  components: {
    BCard,
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";
.brand-text {
  font-family: Noto Sans;
  font-size: 25px;
  font-weight: 700;
  line-height: 44px;
}

.sub-brand-text {
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
}
.btn__setPw-wrap {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .btn__setPw{
    background:#377DFF !important;
    border: none;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: #ffffff;
    padding:10px 30px;
    border-radius: 10px;
  }
}
.btn__setPw-wrap .btn__setPw:hover{
  opacity: 0.8;
}
</style>